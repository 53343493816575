	$(window).on('load', function () {
		$('[data-wy], [data-wyanim]').waypoint({
			handler: function () {
				$(this.element).addClass('wy-mode wy-anim');
			},
			offset: '90%'
		});

		$('[data-wynooff]').waypoint({
			handler: function () {
				$(this.element).addClass('wy-mode wy-anim');
			},
			offset: '100%'
		});

		$('[data-wy], [data-wyanim], [data-wynooff]').on(
			"webkitAnimationEnd oanimationend msAnimationEnd animationend",
			function () {
				$(this).removeClass('wy-mode');
			}
		);
	});
	$(document).ready(function () {
		$('.formcontrol').each(function(){
			if ($(this).val()){
			$(this).closest('.form-group').addClass("quote");
			}
		});

		$(".form-group .formcontrol").on('keyup focusout',function() {
			if ($(this).val().length === 0) {
				$(this).closest('.form-group').removeClass('quote').removeClass('active-form');
			} else {
				$(this).closest('.form-group').addClass('quote').addClass('active-form');
			}
		})
		
		//$(".page-popup").fancybox();
		
		//IMAGE BG
		$(".div-bg").each( function(){
			var imageUrl = $(this).find('img').attr("src");			
			$(this).find('img').css("visibility","hidden");			
			$(this).css('background-image', 'url(' + imageUrl + ')').css("background-repeat","no-repeat").css("background-size","cover").css("background-position","50% 50%");		
		});
		
		//BANER CAROSAL
		$(".banerCarosal").slick({
			arrows:false,
			autoplay: true,
			dots: false,
			responsive: [{ 
				breakpoint: 500,
				settings: {
					//dots: false,
					arrows: false,
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
				} 
			}]
		});

		$(".courseCarosal").slick({
			arrows:true,
			autoplay: true,
			dots: false,
			prevArrow:'<svg class="prevarrow" width="35" height="35" viewBox="0 0 40.332 40.332"><path id="right-chevron" d="M20.166 0A20.166 20.166 0 1 1 0 20.166 20.166 20.166 0 0 1 20.166 0zm2.484 31.52l1.883-1.877-9.446-9.477 9.446-9.477-1.883-1.877-11.317 11.354z" class="cls-1" fill="#808080"/></svg>',
			nextArrow:'<svg class="nextarrow" width="35" height="35" viewBox="0 0 40.332 40.332"><path id="right-chevron" d="M20.166 0a20.166 20.166 0 1 0 20.166 20.166A20.166 20.166 0 0 0 20.166 0zm-2.484 31.52L15.8 29.643l9.446-9.477-9.446-9.477 1.883-1.877L29 20.166z" class="cls-1" fill="#808080"/></svg>',	
			responsive: [{ 
				breakpoint: 500,
				settings: {
					//dots: false,
					arrows: false,
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
				} 
			}]
		});
		
		//NURSERY LIST CAROSAL
		//PAGE TAB
		$('.dfTabnav ul.tabs li').click(function(){
			var tab_id = $(this).attr('data-tab');
			$('ul.tabs li').removeClass('current');
			$('.tab-content').removeClass('current');
			$(this).addClass('current');
			$("#"+tab_id).addClass('current');
		});

		$('.tabCarosal').slick({
			centerPadding: '60px',
			slidesToShow: 4,
			arrows: true,
			infinite: false,
			prevArrow:'<svg class="slickPrev" width="35" height="35" viewBox="0 0 35 35"><path id="right-chevron" d="M17.5,0A17.5,17.5,0,1,1,0,17.5,17.5,17.5,0,0,1,17.5,0Zm2.156,27.353,1.634-1.629-8.2-8.224,8.2-8.224L19.656,7.647,9.835,17.5Z" fill="#e5e5e5"/></svg>',
			nextArrow:'<svg class="slickNext" width="35" height="35" viewBox="0 0 35 35"><path id="right-chevron" d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0ZM15.344,27.353,13.71,25.724l8.2-8.224-8.2-8.224,1.634-1.629L25.165,17.5Z" fill="#e5e5e5"/></svg>',
			
			responsive: [
				{
				breakpoint: 1199,
				settings: {
					arrows: true,
					centerPadding: '40px',
					slidesToShow: 3
				}
				},
				{
					breakpoint: 1024,
					settings: {
					arrows: true,
					centerPadding: '40px',
					slidesToShow: 2
					}
				},
				{
				breakpoint: 767,
				settings: {
					centerPadding: '0',
					slidesToShow: 1
				}
				}
			]
		});
		
		$('.bootCampCarosal').slick({
			centerPadding: '60px',
			slidesToShow: 3,
			arrows: true,
			prevArrow:'<svg class="slickPrev" width="35" height="35" viewBox="0 0 35 35"><path id="right-chevron" d="M17.5,0A17.5,17.5,0,1,1,0,17.5,17.5,17.5,0,0,1,17.5,0Zm2.156,27.353,1.634-1.629-8.2-8.224,8.2-8.224L19.656,7.647,9.835,17.5Z" fill="#e5e5e5"/></svg>',
			nextArrow:'<svg class="slickNext" width="35" height="35" viewBox="0 0 35 35"><path id="right-chevron" d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0ZM15.344,27.353,13.71,25.724l8.2-8.224-8.2-8.224,1.634-1.629L25.165,17.5Z" fill="#e5e5e5"/></svg>',
			
			responsive: [
			{
			breakpoint: 991,
			settings: {
				arrows: true,
				centerPadding: '40px',
				slidesToShow: 2
			}
			},
			{
			breakpoint: 767,
			settings: {
				centerPadding: '0',
				slidesToShow: 1
			}
			}
		]
		});
		
		$('.testimonialCarosal').slick({
			autoplay: true,
			variableWidth: true,
			centerMode: true,
			centerPadding: '60px',
			slidesToShow: 3,
			arrows: false,
			dots: true,
			responsive: [
				{
				breakpoint: 991,
				settings: {
					variableWidth: false,
					centerPadding: '40px',
					slidesToShow: 1
				}
				},
				{
				breakpoint: 800,
				settings: {
					variableWidth: false,
					centerPadding: '0',
					slidesToShow: 1
				}
				}
			]
		});
		
		//COURSE DETAILS TAB NAV
		$('.tabArea .tabNav ul li .courseNavtab').click(function(){
			var tab_id = $(this).attr('data-tab');
			$('ul.tabs li').removeClass('current');
			$('.tab-content').removeClass('current');
			$(this).addClass('current');
			$("#"+tab_id).addClass('current');
		});
		// $('.courseNavRow ul li a span').click(function(){
		// 	var tab_id = $(this).attr('data-tab');
		// 	$('ul.tabs li a.active').removeClass('active');
		// 	$('.tab-content').removeClass('current');
		// 	$(this).parent().parent().siblings().find('a').removeClass('active');
		// 	$(this).parent().addClass('active');
		// 	$("#"+tab_id).addClass('current');
		// });
	});

$(window).scroll(function(){
	
	if($('.count-box').length){
		$('.count-box').appear(function(){
	
			var $t = $(this),
				n = $t.find(".count-text").attr("data-stop"),
				r = parseInt($t.find(".count-text").attr("data-speed"), 10);
				
			if (!$t.hasClass("counted")) {
				$t.addClass("counted");
				$({
					countNum: $t.find(".count-text").text()
				}).animate({
					countNum: n
				}, {
					duration: r,
					easing: "linear",
					step: function() {
						$t.find(".count-text").text(Math.floor(this.countNum));
					},
					complete: function() {
						$t.find(".count-text").text(this.countNum);
					}
				});
			}
			
		},{accY: 0});
	}
	//COURSE DETAILS MAIN NAVIGATION POSITION FIXED
	if ($(window).width() > 200) {
		var stickHeight = $('.dfHeader').outerHeight();
		var stickHeight1 = $('.courseDetailsBaner').outerHeight();
		var stickHeight2 = $('.dtlHolder1').outerHeight();
		var stickHeight3 = $('#scroll1').outerHeight();
		if( $(window).scrollTop() > stickHeight + stickHeight1 + stickHeight2){
			//$('.courseNavHolder').addClass('ifFixed');
		}else{
			//$('.courseNavHolder').removeClass('ifFixed');
		}
		if( $(window).scrollTop() > stickHeight + stickHeight1 + stickHeight2 + stickHeight3 + 150){
			//$('.tabArea').addClass('tabFixed');
		}else{
			//$('.tabArea').removeClass('tabFixed');  
		}
	}

	//COURSE DETAILS TAB POSITION FIXED
	if( $(window).scrollTop() > 150){
		$('.dfHeader').addClass('fixHeader');
	}
	else{
		$('.dfHeader').removeClass('fixHeader');  
	}


});	

/*if( $(window).width() > 767 ){

}else{

}*/
	
$(window).on('load', function () {
	$(".scrollText").mCustomScrollbar({
		theme:"minimal",
		scrollbarPosition:"outside"
	});
	// Elements Animation 
	if($('.wow').length){
		var wow = new WOW( 
			{
			boxClass:     'wow',     
			animateClass: 'animated', 
			offset:       0,         
			mobile:       false,      
			live:         true     
			}
		);
		wow.init();
	}
});

$(document).on('click', '.courseNavRow ul li a[href^="#"]', function(e) {
	e.preventDefault();
	var id = $(this).attr('href');
	var $id = $(id);
	if ($id.length === 0) {
		return;
	}
	if ($(window).width() > 700) {
		var pos = $id.offset().top - 110;
	}else{  
		var pos = $id.offset().top - 0;
	}
	$('body, html').animate({scrollTop: pos}, 1000);
	
	//$('.courseNavRow').removeClass('open');
});

$(document).on('click', '.tabNav ul li a[href^="#"]', function(e) {
	e.preventDefault();
	var id = $(this).attr('href');
	var $id = $(id);
	if ($id.length === 0) {
		return;
	}
	if ($(window).width() > 200){
		var pos = $id.offset().top - 60;  
	}else{
		var pos = $id.offset().top - 0; 
	}
	$('body, html').animate({scrollTop: pos}, 1000);
});

// $(window).scroll(function() {
// 	var scrollDistance = $(window).scrollTop();
// 	$('.pageSection').each(function(i) {
// 		if ($(window).width() > 1360) {
// 			if ($(this).position().top <= scrollDistance - 500) {
// 				$('.courseNavRow ul li a.active').removeClass('active');  
// 				$('.courseNavRow ul li a').eq(i).addClass('active');
// 			}
// 		}
// 		else{
// 			if ($(this).position().top <= scrollDistance - 300) {
// 				$('.courseNavRow ul li a.active').removeClass('active');  
// 				$('.courseNavRow ul li a').eq(i).addClass('active');
// 			}
// 		}
// 	});
// }).scroll();







